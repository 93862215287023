jQuery(function() {
    jQuery(document).ajaxComplete(function() {
        if (jQuery('body').hasClass('woocommerce-checkout') || jQuery('body').hasClass('woocommerce-cart')) {
            jQuery('html, body').stop();
        }
    });
});

jQuery(document).on('submit', 'form#order_review', function(e) {
    jQuery("#place_order").attr("disabled", "disabled");
});
